<template>
  <b-card>
    <validation-observer
      ref="simpleRules"
      v-slot="{ reset }"
    >
      <!-- form -->
      <b-form
        ref="changepassform"
        @reset.prevent="reset"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="form.oldPassword"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="form.newPassword"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                    @keyup="checkNewPassword()"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <b-col cols="12">
            <b-form-checkbox
              v-model="passwordPolicy"
              value="A"
              class="passwordPolicyCBox"
              :class="{ 'passwordPolicyCBoxPassed': passwordPolicy.includes('A') ? true : false }"
            >
              Password must not be empty
            </b-form-checkbox>
            <b-form-checkbox
              v-model="passwordPolicy"
              value="B"
              class="passwordPolicyCBox"
              :class="{ 'passwordPolicyCBoxPassed': passwordPolicy.includes('B') ? true : false }"
            >
              Password must be longer than 8 characters
            </b-form-checkbox>
            <b-form-checkbox
              v-model="passwordPolicy"
              value="C"
              class="passwordPolicyCBox"
              :class="{ 'passwordPolicyCBoxPassed': passwordPolicy.includes('C') ? true : false }"
            >
              Password must have a symbol
            </b-form-checkbox>
            <b-form-checkbox
              v-model="passwordPolicy"
              value="D"
              class="passwordPolicyCBox"
              :class="{ 'passwordPolicyCBoxPassed': passwordPolicy.includes('D') ? true : false }"
            >
              Password must have an uppercase letter
            </b-form-checkbox>
          </b-col>
        </b-row>

        <!-- retype password -->
        <b-row
          class="mt-1"
        >
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype New Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="form.retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!--/ retype password -->
        <b-row>
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mt-1 mr-1"
              @click="validationForm('change_password')"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-dark"
              class="mt-1"
              @click="resetForm()"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components,  no-unused-vars */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from '@axios'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const initialForm = ref({
      oldPassword: '',
      newPassword: '',
      retypePassword: '',
    })

    const form = ref(JSON.parse(JSON.stringify(initialForm.value)))

    const passwordFieldTypeOld = ref('password')
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')
    const passwordPolicy = ref([])
    const simpleRules = ref(null)
    const changepassform = ref(null)

    const passwordToggleIconOld = ref(computed(() => (passwordFieldTypeOld.value === 'password' ? 'EyeIcon' : 'EyeOffIcon')))

    const passwordToggleIconNew = ref(computed(() => (passwordFieldTypeNew.value === 'password' ? 'EyeIcon' : 'EyeOffIcon')))

    const passwordToggleIconRetype = ref(computed(() => (passwordFieldTypeRetype.value === 'password' ? 'EyeIcon' : 'EyeOffIcon')))

    const userData = JSON.parse(localStorage.getItem('userData'))

    const togglePasswordOld = () => {
      passwordFieldTypeOld.value = passwordFieldTypeOld.value === 'password' ? 'text' : 'password'
    }

    const togglePasswordNew = () => {
      passwordFieldTypeNew.value = passwordFieldTypeNew.value === 'password' ? 'text' : 'password'
    }

    const togglePasswordRetype = () => {
      passwordFieldTypeRetype.value = passwordFieldTypeRetype.value === 'password' ? 'text' : 'password'
    }

    // const isPassed = ref(computed(value => passwordPolicy.value.includes(value)))

    const checkNewPassword = () => {
      // console.log(this.form.newPassword)
      passwordPolicy.value = []
      if (form.value.newPassword.trim().length !== 0) {
        if (passwordPolicy.value.indexOf('A') < 0) {
          passwordPolicy.value.push('A')
        }
      } else if (passwordPolicy.value.indexOf('A') > -1) {
        passwordPolicy.value.splice(passwordPolicy.value.indexOf('A'))
      }
      if (form.value.newPassword.length > 8) {
        if (passwordPolicy.value.indexOf('B') < 0) {
          passwordPolicy.value.push('B')
        }
      } else if (passwordPolicy.value.indexOf('B') > -1) {
        passwordPolicy.value.splice(passwordPolicy.value.indexOf('B'))
      }

      if (form.value.newPassword.match(/[|\\/~^:,;?!&%$@#*+]/) !== null) {
        if (passwordPolicy.value.indexOf('C') < 0) {
          passwordPolicy.value.push('C')
        }
      } else if (passwordPolicy.value.indexOf('C') > -1) {
        passwordPolicy.value.splice(passwordPolicy.value.indexOf('C'))
      }
      // eslint-disable-next-line
      if (form.value.newPassword.match(/[A-Z]/) !== null) {
        if (passwordPolicy.value.indexOf('D') < 0) {
          passwordPolicy.value.push('D')
        }
      } else if (passwordPolicy.value.indexOf('D') > -1) {
        passwordPolicy.value.splice(passwordPolicy.value.indexOf('D'))
      }
    }

    const resetForm = () => {
      changepassform.value.reset()
      form.value = JSON.parse(JSON.stringify(initialForm.value))
      passwordPolicy.value = []
    }

    const changePasswordAction = () => {
      const formData = {
        id: userData.id,
        data: form.value,
      }
      console.log(formData.value)
      // eslint-disable-next-line
      axios.post(`${Vue.prototype.$apiUrl2}users/changePassword`, {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          if (response.data === 'not_match' || response.data === 'incorrect_password' || response.data === 'password_policy_error') {
            let message = ''
            if (response.data === 'not_match') {
              message = 'Password did not match'
            } else if (response.data === 'password_policy_error') {
              message = 'The New Password does not meet the Password Policy'
            } else {
              message = 'Incorrect Password'
            }
            Swal.fire({
              icon: 'warning',
              title: message,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            resetForm()

            Swal.fire({
              icon: 'success',
              title: 'Password Succesfully Updated',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    }

    const validationForm = key => {
      let passchecker = true
      let passchecker2 = true
      let passchecker3 = true
      simpleRules.value.validate().then(success => {
        if (success) {
          if (form.value.newPassword === form.value.oldPassword) {
            Swal.fire({
              icon: 'warning',
              title: 'New Password is the same with Old Password',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            passchecker3 = false
          }

          if (form.value.newPassword !== form.value.retypePassword) {
            Swal.fire({
              icon: 'warning',
              title: 'Password did not match',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            passchecker = false
          }

          if (passwordPolicy.value.length < 4) {
            Swal.fire({
              icon: 'warning',
              title: 'The New Password does not meet the Password Policy',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            passchecker2 = false
          }

          if (key === 'change_password' && passchecker === true && passchecker2 === true && passchecker3 === true) {
            // console.log('yeah')
            changePasswordAction()
          }
        }
      })
    }

    return {
      form,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,
      passwordPolicy,
      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,
      simpleRules,
      changepassform,
      userData,

      // functions
      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype,
      // isPassed,
      resetForm,
      validationForm,
      checkNewPassword,
      changePasswordAction,
    }
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.passwordPolicyCBox {
  pointer-events: none;
  opacity: 0.65;
}
.passwordPolicyCBox.passwordPolicyCBoxPassed{
  opacity: 1 !important;
}
</style>

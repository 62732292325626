<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- bio -->
          <!--
          <b-col cols="12">
            <b-form-group
              label="Bio"
              label-for="bio-area"
            >
              <b-form-textarea
                id="bio-area"
                rows="4"
                placeholder="Your bio data here..."
              />
            </b-form-group>
          </b-col>
          -->
          <!--/ bio -->

          <!-- birth date -->
          <!--
          <b-col md="6">
            <b-form-group
              label-for="example-datepicker"
              label="Birth date"
            >
              <flat-pickr
                class="form-control"
                name="date"
                placeholder="Birth date"
              />
            </b-form-group>
          </b-col>
          -->
          <!--/ birth date -->

          <!-- Country -->
          <!--
          <b-col md="6">
            <b-form-group
              label-for="countryList"
              label="Country"
            >
              <v-select
                id="countryList"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="countryOption"
              />
            </b-form-group>
          </b-col>
          -->
          <!--/ Country -->

          <!-- website -->
          <!--
          <b-col md="6">
            <b-form-group
              label-for="website"
              label="Website"
            >
              <b-form-input
                id="website"
                placeholder="Website address"
              />
            </b-form-group>
          </b-col>
          -->
          <!--/ website -->

          <!-- phone -->
          <!--
          <b-col md="6">
            <b-form-group
              label-for="phone"
              label="Phone"
            >
              <cleave
                id="phone"
                class="form-control"
                :raw="false"
                :options="clevePhone"
                placeholder="Phone number"
              />
            </b-form-group>
          </b-col>
          -->
          <!-- phone -->

          <!-- alert -->
          <b-col
            v-if="uDATA.role === 'agent' || (uDATA.role === 'client' && (uDATA.family_member === '' || uDATA.family_member === 'no' || uDATA.family_member === false || uDATA.family_member === null))"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="info"
              class="mb-50"
            >
              <h4 class="alert-heading">
                You can edit your information on
                <b-link
                  target="_blank"
                  class="alert-link"
                  :to="uDATA.role === 'agent' ? { name: 'apps-hr-form', params: { action: 'edit' }, query: { id: uDATA.cbiId } } : { path: '/clientdashboard/profile-edit' }"
                >
                  {{ redirect }}
                </b-link> page
              </h4>
              <!--
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
              -->
            </b-alert>
          </b-col>
          <!--/ alert -->

          <!-- Field: Mobile Phone -->
          <b-col
            v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Mobile Phone"
              label-for="mobilephone"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Phone"
                :rules="famMemberData.primarycontactnumber === 'mobilephone' ? 'required' : ''"
              >
                <span
                  class="row col p-0 m-0"
                >
                  <b-input-group
                    class="col mr-2 p-0"
                  >
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <b-form-input
                      id="mobilephone"
                      v-model="famMemberData.mobile_phone"
                      v-mask="['(###) ###-####']"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                    />
                  </b-input-group>
                  <b-form-checkbox
                    v-model="famMemberData.primarycontactnumber"
                    value="mobilephone"
                    class="col-md-3 align-self-center"
                  >
                    Primary
                  </b-form-checkbox>
                </span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Work Phone -->
          <b-col
            v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Work Phone"
              label-for="workphone"
            >
              <validation-provider
                #default="{ errors }"
                name="Work Phone"
                :rules="famMemberData.primarycontactnumber === 'workphone' ? 'required' : ''"
              >
                <span
                  class="row col p-0 m-0"
                >
                  <b-input-group
                    class="col mr-2 p-0"
                  >
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <b-form-input
                      id="workphone"
                      v-model="famMemberData.work_phone"
                      v-mask="['(###) ###-####']"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                    />
                  </b-input-group>
                  <b-form-checkbox
                    v-model="famMemberData.primarycontactnumber"
                    value="workphone"
                    class="col-md-3 align-self-center"
                  >
                    Primary
                  </b-form-checkbox>
                </span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Home Phone -->
          <b-col
            v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Home Phone"
              label-for="homephone"
            >
              <validation-provider
                #default="{ errors }"
                name="Home Phone"
                :rules="famMemberData.primarycontactnumber === 'homephone' ? 'required' : ''"
              >
                <span
                  class="row col p-0 m-0"
                >
                  <b-input-group
                    class="col mr-2 p-0"
                  >
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <b-form-input
                      id="homephone"
                      v-model="famMemberData.home_phone"
                      v-mask="['(###) ###-####']"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                    />
                  </b-input-group>
                  <b-form-checkbox
                    v-model="famMemberData.primarycontactnumber"
                    value="homephone"
                    class="col-md-3 align-self-center"
                  >
                    Primary
                  </b-form-checkbox>
                </span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mt-1 mr-1"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mt-1"
              variant="outline-dark"
              @click="$emit('fetch-my-data')"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BLink, BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import Cleave from 'vue-cleave-component'
import { required } from '@validations'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Swal from 'sweetalert2'
import store from '@/store'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BLink,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupPrepend,
    // vSelect,
    // flatPickr,
    // Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
    Ripple,
  },
  /* props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {
    resetForm() {
      // this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
  }, */
  setup(props, { emit }) {
    const uDATA = JSON.parse(localStorage.getItem('userData'))
    const famMemberData = ref(computed(() => store.state.account_setting.fam_member_information))
    const simpleRules = ref(null)

    // *===============================================*
    // *--------- VALIDATE AND SUBMIT FORM ------------*
    // *===============================================*
    const onSaveData = () => {
      const formData = new FormData()
      formData.append('id', uDATA.id)
      formData.append('work_phone', famMemberData.value.work_phone)
      formData.append('mobile_phone', famMemberData.value.mobile_phone)
      formData.append('home_phone', famMemberData.value.home_phone)
      formData.append('primarycontactnumber', famMemberData.value.primarycontactnumber)
      // console.log(uDATA.family_member)
      if (uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null) {
        formData.append('fam_id', uDATA.familyId)
      }
      /* for (var value of formData.values()) {
        console.log(value);
      } */
      store.dispatch('account_setting/saveInformationData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          onSaveData()
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    const redirect = uDATA.role === 'agent' ? 'COC Forms: Basic Information' : 'Edit Profile'

    return {
      validationForm,
      onSaveData,
      famMemberData,
      uDATA,
      simpleRules,
      required,
      redirect,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <div>
    <b-card>

      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-avatar
              ref="previewEl"
              rounded
              variant="primary"
              :src="generalData.profile_picture"
              :text="avatarText(generalData.first_name + ' ' + generalData.last_name)"
              size="90px"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-2 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-b-modal.modal-removeprofpic
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-dark"
            class="mr-75"
            :disabled="generalData.profile_picture != '' ? false:true"
          >
            Remove
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 1MB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <b-col
        v-if="uDATA.role === 'agent' || (uDATA.role === 'client' && (uDATA.family_member === '' || uDATA.family_member === 'no' || uDATA.family_member === false || uDATA.family_member === null))"
        cols="12"
        class="mt-75"
      >
        <b-alert
          show
          variant="info"
          class="mb-50"
        >
          <h4 class="alert-heading">
            You can edit your information on
            <b-link
              target="_blank"
              class="alert-link"
              :to="uDATA.role === 'agent' ? { name: 'apps-hr-form', params: { action: 'edit' }, query: { id: uDATA.cbiId } } : { path: '/clientdashboard/profile-edit' }"
            >
              {{ redirect }}
            </b-link>page
          </h4>
        </b-alert>
      </b-col>

      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col
              :sm="uDATA.family_member !== null && uDATA.family_member !== 'no' && uDATA.family_member !== false ? 4 : 6"
            >
              <b-form-group
                label="First Name"
                label-for="first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="generalData.first_name"
                    name="first-name"
                    placeholder="First Name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="uDATA.role === 'agent' || (uDATA.role === 'client' && (uDATA.family_member === null || uDATA.family_member === '' || uDATA.family_member === false)) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="uDATA.family_member !== null && uDATA.family_member !== 'no' && uDATA.family_member !== false"
              sm="4"
            >
              <b-form-group
                label="Middle Name"
                label-for="middle-name"
              >
                <b-form-input
                  v-model="famMemberData.middle_name"
                  name="middle-name"
                  placeholder="Middle Name"
                />
              </b-form-group>
            </b-col>
            <b-col
              :sm="uDATA.family_member !== null && uDATA.family_member !== 'no' && uDATA.family_member !== false ? 4 : 6"
            >
              <b-form-group
                label="Last Name"
                label-for="last-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="generalData.last_name"
                    name="last-name"
                    placeholder="Last Name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="uDATA.role === 'agent' || (uDATA.role === 'client' && (uDATA.family_member === null || uDATA.family_member === 'no' || uDATA.family_member === false)) ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="generalData.email_address"
                    name="email"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="uDATA.role === 'admin' ? false : true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- alert -->
            <!--
            <b-col
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Your email is not confirmed. Please check your inbox.
                </h4>
                <div class="alert-body">
                  <b-link class="alert-link">
                    Resend confirmation
                  </b-link>
                </div>
              </b-alert>
            </b-col>
            -->
            <!--/ alert -->
            <b-col
              cols="12"
              md="6"
            />
            <!-- Field: Mobile Phone -->
            <b-col
              v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
              cols="12"
              md="6"
            >
              <b-form-group
                label="Mobile Phone"
                label-for="mobilephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Phone"
                  :rules="famMemberData.primarycontactnumber === 'mobilephone' ? 'required' : ''"
                >
                  <span
                    class="row col p-0 m-0"
                  >
                    <b-input-group
                      class="col mr-2 p-0"
                    >
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="mobilephone"
                        v-model="famMemberData.mobile_phone"
                        v-mask="['(###) ###-####']"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-checkbox
                      v-model="famMemberData.primarycontactnumber"
                      value="mobilephone"
                      class="col-md-3 align-self-center"
                    >
                      Primary
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Work Phone -->
            <b-col
              v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
              cols="12"
              md="6"
            >
              <b-form-group
                label="Work Phone"
                label-for="workphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Work Phone"
                  :rules="famMemberData.primarycontactnumber === 'workphone' ? 'required' : ''"
                >
                  <span
                    class="row col p-0 m-0"
                  >
                    <b-input-group
                      class="col mr-2 p-0"
                    >
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="workphone"
                        v-model="famMemberData.work_phone"
                        v-mask="['(###) ###-####']"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-checkbox
                      v-model="famMemberData.primarycontactnumber"
                      value="workphone"
                      class="col-md-3 align-self-center"
                    >
                      Primary
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Home Phone -->
            <b-col
              v-if="uDATA.family_member !== '' && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== null"
              cols="12"
              md="6"
            >
              <b-form-group
                label="Home Phone"
                label-for="homephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Home Phone"
                  :rules="famMemberData.primarycontactnumber === 'homephone' ? 'required' : ''"
                >
                  <span
                    class="row col p-0 m-0"
                  >
                    <b-input-group
                      class="col mr-2 p-0"
                    >
                      <b-input-group-prepend is-text>
                        US (+1)
                      </b-input-group-prepend>
                      <b-form-input
                        id="homephone"
                        v-model="famMemberData.home_phone"
                        v-mask="['(###) ###-####']"
                        :state="errors.length > 0 ? false : null"
                        type="tel"
                      />
                    </b-input-group>
                    <b-form-checkbox
                      v-model="famMemberData.primarycontactnumber"
                      value="homephone"
                      class="col-md-3 align-self-center"
                    >
                      Primary
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="(uDATA.family_member !== '' && uDATA.family_member !== null && uDATA.family_member !== 'no' && uDATA.family_member !== false) || uDATA.role === 'admin'"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-dark"
                class="mt-2"
                @click="$emit('fetch-my-data')"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- remove profile modal  -->
    <b-modal
      id="modal-removeprofpic"
      title="Remove Photo"
      centered
    >
      <div class="mx-0.5 my-1 text-centered">
        <span>
          Are you sure you want to remove this photo?
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="removeProfPic(); ok()"
        >
          Remove
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BFormTextarea, BFormCheckboxGroup, BInputGroup, BInputGroupPrepend, BFormFile, BButton, BForm, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// eslint-disable-next-line
import { ref, onUnmounted, computed, watch } from '@vue/composition-api'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
// import clientDashboardStoreModule from '@/views/templates/clientdashboard/clientDashboardStoreModule.js'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BFormTextarea,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupPrepend,
    // eslint-disable-next-line
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
  },
  /* props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  }, */
  data() {
    return {
      // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  /* methods: {
  }, */
  setup(props, { emit }) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const uDATA = JSON.parse(localStorage.getItem('userData'))
    const generalData = ref(computed(() => store.state.account_setting.general))
    const famMemberData = ref(computed(() => store.state.account_setting.fam_member_information))
    const simpleRules = ref(null)
    // const famMemberDataCopy = ref(JSON.parse(JSON.stringify(famMemberData.value)))
    // const generalDataCopy = ref(JSON.parse(JSON.stringify(generalData.value)))
    const canSave = ref(true)
    watch(generalData.value, () => {
      canSave.value = false
      // console.log(canSave)
    })
    // console.log(generalData.first_name)

    /* if (uDATA.family_member !== '' || uDATA.family_member !== null || uDATA.family_member !== false) {
      const CLIENT_DASHBOARD_APP_STORE_MODULE_NAME = 'client_dashboard'

      // Register module
      if (!store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
        store.registerModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME, clientDashboardStoreModule)
      }

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
          store.unregisterModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)
        }
      })

      // eslint-disable-next-line
      info = ref(computed(() => store.state.client_dashboard.clientInfo))
    } */

    // *===============================================*
    // *--------- CHANGE PIC --------------------------*
    // *===============================================*

    /* eslint-disable */
    const imageChecker = () => {
      if (refInputEl.value.files.length > 0) {
        const image = refInputEl.value.files[0]
        const fileSize = image.size
        var fileMb = fileSize / 1024 ** 2
      } else {
        return false
      }

      if (fileMb > 2) {
        // showMessage('Image size is too large. Max of 2mb only.', 'error', 'center')
        Swal.fire({
          icon: 'error',
          title: 'File Was Not Uploaded',
          text: 'Your file is too large. The allowed file size should not exceed 1MB.',
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return false
      }

      return true
    }
    /* eslint-enable */

    // eslint-disable-next-line no-unused-vars
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // to upload image
      if (refInputEl.value.files && imageChecker()) {
        const formData = new FormData()
        // console.log(refInputEl.value.files)

        formData.append('files', refInputEl.value.files[0])
        formData.append('id', uDATA.id)

        store.dispatch('account_setting/ChangeProfilePicture', formData)
          .then(response => {
            // console.log(response.data.response.data)
            store.commit('account_setting/SET_PROF_PIC', response.data.response.data)

            uDATA.avatar = response.data.response.data
            localStorage.setItem('userData', JSON.stringify(uDATA))
            // eslint-disable-next-line no-param-reassign
            // props.userData.avatar = base64

            Swal.fire({
              icon: 'success',
              title: 'Changed Image Succesfully',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          // eslint-disable-next-line
          .catch(error => {
            console.log(error)
            Swal.fire({
              icon: 'error',
              title: 'File Was Not Uploaded',
              text: 'There was a problem uploading the image. Please try again.',
              showConfirmButton: false,
              timer: 2500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            /* Enables btn */
            // btnDisabled.value = false
            // console.log(error)
            // emit('toast-notif', 'Error', '', 'AlertTriangleIcon', 'danger')
          })
      }
    })

    // *===============================================*
    // *--------- REMOVE PIC --------------------------*
    // *===============================================*
    const removeProfPic = () => {
      const formData = new FormData()
      formData.append('id', uDATA.id)
      store.dispatch('account_setting/removeProfPic', formData)
        .then(() => {
          store.commit('account_setting/SET_PROF_PIC', '')
          uDATA.avatar = ''
          localStorage.setItem('userData', JSON.stringify(uDATA))
          Swal.fire({
            icon: 'success',
            title: 'Removed Image Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }

    // *===============================================*
    // *--------- VALIDATE AND SUBMIT FORM ------------*
    // *===============================================*
    // eslint-disable-next-line no-unused-vars
    const onSaveData = () => {
      const formData = new FormData()
      formData.append('id', uDATA.id)
      formData.append('first_name', generalData.value.first_name)
      formData.append('last_name', generalData.value.last_name)
      formData.append('email_address', generalData.value.email_address)

      formData.append('work_phone', famMemberData.value.work_phone)
      formData.append('mobile_phone', famMemberData.value.mobile_phone)
      formData.append('home_phone', famMemberData.value.home_phone)
      formData.append('primarycontactnumber', famMemberData.value.primarycontactnumber)

      // console.log(uDATA.family_member)
      if (uDATA.family_member !== null && uDATA.family_member !== 'no' && uDATA.family_member !== false && uDATA.family_member !== '') {
        formData.append('middle_name', famMemberData.value.middle_name)
        formData.append('fam_id', uDATA.familyId)
      }
      /* for (var value of formData.values()) {
        console.log(value);
      } */
      store.dispatch('account_setting/saveGeneralData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
          canSave.value = true
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          if (!canSave.value) {
            onSaveData()
          }
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'No changes supplied',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    const redirect = uDATA.role === 'agent' ? 'COC Forms: Basic Information' : 'Edit Profile'
    watch(() => {

    })
    return {
      refInputEl,
      previewEl,
      uDATA,
      // info,
      // fetchMyData,
      required,
      email,
      simpleRules,
      validationForm,
      avatarText,
      generalData,
      famMemberData,
      inputImageRenderer,
      removeProfPic,
      redirect,
    }
  },
}
</script>

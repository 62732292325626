<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        @fetch-my-data="fetchMyData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <!-- <b-tab
      v-if="uDATA.role !== 'admin'"
    >
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <account-setting-information
        v-if="uDATA.role !== 'admin'"
        @fetch-my-data="fetchMyData"
      />
    </b-tab> -->

    <!-- social links
    <b-tab>

      //comment title
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    //comment notification
    <b-tab>

      //comment title
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>
    -->
  </b-tabs>
</template>

<script>
/* eslint-disable vue/no-unused-components,  no-unused-vars */
// import axios from '@axios'
// import Vue from 'vue'
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import AccountSettingStoreModule from '@/views/pages/account-setting/AccountSettingStoreModule.js'
import clientDashboardStoreModule from '@/views/templates/clientdashboard/clientDashboardStoreModule.js'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  setup() {
    const uDATA = JSON.parse(localStorage.getItem('userData'))
    // Register module
    /* if (!store.hasModule('account_setting')) {
      store.registerModule('account_setting', AccountSettingStoreModule)
    } */
    // UnRegister on leave
    /* onUnmounted(() => {
      if (store.hasModule('account_setting')) {
        store.unregisterModule('account_setting')
      }
    }) */
    // const general = ref(computed(() => store.state.account_setting.general))

    /* if (uDATA.family_member !== '' || uDATA.family_member !== null || uDATA.family_member !== false) {
      // Register module
      if (!store.hasModule('client_dashboard')) {
        store.registerModule('client_dashboard', clientDashboardStoreModule)
      }
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('client_dashboard')) {
          store.unregisterModule('client_dashboard')
        }
      })

      // eslint-disable-next-line
      // const info = ref(computed(() => store.state.client_dashboard.clientInfo))
    } */

    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      /* eslint-disable */
      // Get Account Settings Data
      store.dispatch('account_setting/getAccountSettingsData', uDATA.id)
      
      /* if (uDATA.family_member !== '' && uDATA.family_member !== null && uDATA.family_member !== false) {
      }
      else if ( uDATA.role === 'client') {
      }
      else if ( uDATA.role === 'agent') {
      }
      else { // if admin
      } */
      /* eslint-enable */
    }
    fetchMyData()

    return {
      uDATA,
      fetchMyData,
    }
  },
}
</script>
